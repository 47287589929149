import Button from '@/components/Button'
import { ChangeEventHandler } from 'react'
import cn from '@/lib/util/cn'
import { isArray } from 'lodash'
import Input from '@/components/Input'

type ButtonData = {
  id: string
  text: string
  onClick?: () => void
  isLoading?: boolean
  isDisabled?: boolean
}

export default function UpperBar({
  searchValue,
  handleSearchChange,
  title,
  className,
  rowTitleClassName,
  inputClassName,
  hideSearch,
  buttonData
}: {
  title: string
  className?: string
  rowTitleClassName?: string
  inputClassName?: string
  hideSearch?: boolean
  searchValue?: string
  handleSearchChange?: ChangeEventHandler<HTMLInputElement>
  buttonData?: ButtonData | ButtonData[]
}) {
  const buttonDataArray = buttonData ? isArray(buttonData) ? buttonData : [ buttonData ] : undefined

  return (
    <>
      <UpperBarButtons buttonDataArray={buttonDataArray} className="lg:hidden" />
      <div
        className={cn(
          '-mb-6 w-full grid-cols-[auto,1fr] grid-rows-2 justify-between gap-4 grid-areas-[title_buttons,search_search] lg:mb-0 lg:grid lg:grid-cols-[auto,1fr,auto] lg:grid-rows-1 lg:grid-areas-[title_search_buttons]',
          hideSearch && 'grid-rows-1 grid-areas-[title_buttons] lg:grid-areas-[title_buttons]',
          className
        )}
        >
        <div className={cn('self-center text-[18px] font-semibold grid-in-[title]', rowTitleClassName)}>{title}</div>
        {!hideSearch &&
          <Input
            className={cn('w-full grid-in-[search] lg:w-[290px]', inputClassName)}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
        }
        <UpperBarButtons buttonDataArray={buttonDataArray} className="hidden lg:flex" />
      </div>
    </>
  )
}

const UpperBarButtons = ({
  buttonDataArray,
  className
}: {
  buttonDataArray: ButtonData[] | undefined
  className?: string
}) =>
  <div
    className={cn(
      'ml-auto flex w-full shrink-0 flex-wrap gap-2 justify-self-end overflow-auto grid-in-[buttons] lg:gap-5',
      className
    )}
    >
    {buttonDataArray &&
      buttonDataArray.map((buttonDatum, i) =>
        <Button
          key={i}
          isLoading={buttonDatum.isLoading}
          disabled={buttonDatum.isDisabled}
          onClick={buttonDatum.onClick}
          className="w-fit px-5"
        >
          {buttonDatum.text}
        </Button>
      )}
  </div>

