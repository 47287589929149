import cn from '@/lib/util/cn'
import Link from 'next/link'

export default function ButtonCell({
  children,
  className,
  onClick,
  href
}: {
  children: string | React.ReactElement
  className?: string
  onClick?: (() => void) | null
  href?: string
}) {
  return (
    <div className={cn('flex h-full items-center')}>
      {href ?
        <Link className={cn('block hover:opacity-70', className)} href={href}>
          {children}
        </Link>
       :
        <button
          type="button"
          className={cn('block hover:opacity-70', className)}
          onClick={(e) => {
            e.stopPropagation()
            onClick?.()
          }}
        >
          {children}
        </button>
      }
    </div>
  )
}
