import { z } from 'zod'
import { useMemo, useState } from 'react'
import { ArtistInfo } from '@prisma/client'
import { Permission, Role } from '@/lib/types'
import { isEmpty } from 'lodash'
import ButtonCell from '@/components/DataTable/components/ButtonCell'
import UpperBar from '@/components/DataTable/components/UpperBar'
import { ArrowUpDown } from 'lucide-react'

import { GetAllReleases } from '@/server/routers/release'
import { nextTrpc } from '@/lib/trpc'
import DataTable from '@/components/DataTable'
import { ColumnDef } from '@tanstack/react-table'
import { Card } from '@/components/Card'
import { BaseReleaseSchema, MixReleaseSchema, ReviewSchema } from '@prisma-generated/zod'
import Link from 'next/link'
import { useRouter } from 'next/router'
import CopyAudioUrl from '../CopyAudioUrl'
import { saveAs } from 'file-saver'
import dateSortingFn from '@/lib/util/dateSortingFn'

type BaseRelease = z.infer<typeof BaseReleaseSchema>
type MixRelease = z.infer<typeof MixReleaseSchema>
type Review = z.infer<typeof ReviewSchema>

const convertToCSV = (data: any[]) => {
  const csvRows = []
  const headers = Object.keys(data[0])
  csvRows.push(headers.join(','))

  for (const row of data) {
    const values = headers.map((header) => {
      let value = row[header]
      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value)
      }
      const escape = `${value}`.replace(/"/g, '\\"')
      return `"${escape}"`
    })
    csvRows.push(values.join(','))
  }

  return csvRows.join('\n')
}

export default function ReleaseManagementTable({
  releases,
  artist,
  tableClassName
}: {
  releases: GetAllReleases
  artist: ArtistInfo
  tableClassName?: string
}) {
  const router = useRouter()
  const { baseReleases, mixReleases } = releases
  const [ searchText, setSearchText ] = useState('')

  // const [ baseReleaseToDelete, setBaseReleaseToDelete ] = useState<BaseRelease>()
  // const [ mixReleaseToDelete, setMixReleaseToDelete ] = useState<MixRelease>()

  const { data: me } = nextTrpc.user.me.useQuery()
  const isTeamAdmin = useMemo(
    () =>
      me?.role === Role.ADMIN || me?.team?.some((u) => u.artistId === artist?.id && u.permission === Permission.ADMIN),
    [ me, artist?.id ]
  )
  const tableData = useMemo<Array<BaseRelease | MixRelease>>(
    // @ts-ignore (fix tracks: types here)
    () => [ ...baseReleases, ...mixReleases ],
    [ baseReleases, mixReleases ]
  )

  const tableColumns = useMemo<ColumnDef<BaseRelease | MixRelease, string>[]>(
    () => [
      {
        header: 'Cover Art',
        id: 'coverArt',
        size: 75,
        cell: ({ row: { original: data } }) => <img alt="cover art" src={data.coverArtUrl}></img>
      },
      {
        id: 'type',
        accessorFn: (originalRow) => {
          if ('releaseType' in originalRow) return originalRow.releaseType
          if ('mixType' in originalRow) return originalRow.mixType
        },
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Type <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        accessorKey: 'title',
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Title <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        header: 'Audio',
        id: 'audioUrl',
        size: 75,
        cell: ({ row: { original: data } }) =>
          <>
            <audio src={data.audioUrl} controls={true}></audio>
            <CopyAudioUrl audioUrl={data.audioUrl} />
          </>

      },
      {
        accessorKey: 'recordLabel',
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Record Label <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        accessorFn: (originalRow) => {
          if ('reviews' in originalRow) {
            // @ts-ignore
            const reviews = originalRow.reviews.sort(
              (a: Review, b: Review) => new Date(b.reviewedAt).getTime() - new Date(a.reviewedAt).getTime()
            )
            return reviews[0]?.status
          }
          return 'not available'
        },
        id: 'status',
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Status <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        id: 'preferredReleaseDate',
        accessorKey: 'preferredReleaseDate',
        accessorFn: (originalRow) => {
          if ('preferredReleaseDate' in originalRow) {
            return originalRow.preferredReleaseDate.toDateString()
          }
          return 'Not Set'
        },
        sortingFn: dateSortingFn,
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Preferred Release Date <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        accessorFn: (originalRow) => {
          if ('createdAt' in originalRow) {
            return originalRow.createdAt.toDateString()
          }
          return 'Not Set'
        },
        sortingFn: dateSortingFn,
        header: ({ column }) =>
          <button className="flex" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Created At <ArrowUpDown className="ml-2 mt-1 h-4 w-4" />
          </button>

      },
      {
        id: 'editButton',
        size: 35,
        cell: ({ row: { original: data } }) =>
          <ButtonCell
            className="group relative inline-flex h-10 w-fit items-center justify-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap break-words rounded-3xl bg-primary px-5 py-2 text-sm font-normal tracking-widest text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
            href={`/releases/${data.id}`}
            >
            REVIEW
          </ButtonCell>

      }
      // {
      //   id: 'deleteButton',
      //   size: 16,
      //   cell: ({ row: { original: data } }) =>
      //     isTeamAdmin &&
      //       <DeleteButtonCell
      //         handleClick={() => 'releaseType' in data ? setBaseReleaseToDelete(data) : setMixReleaseToDelete(data)}
      //       />

      // }
    ],
    [ artist, isTeamAdmin ]
  )

  console.log(tableData)

  return (
    <>
      {/* {isTeamAdmin &&
        <DeleteTeamMemberModal
          artist={artist}
          inviteToDelete={inviteToDelete}
          teamMemberToDelete={teamMemberToDelete}
          isOpen={!!inviteToDelete || !!teamMemberToDelete}
          setIsOpen={toggleTeamMemberDialog}
        />
      } */}
      <UpperBar
        title="RELEASES"
        searchValue={searchText}
        handleSearchChange={(e) => setSearchText(e.target.value)}
        buttonData={[
          {
            id: 'add-release-button',
            text: 'ADD RELEASE',
            onClick: () => router.push('/releases/create')
          },
          {
            id: 'export-csv-button',
            text: 'EXPORT CSV',
            onClick: () => {
              const search = searchText.toLowerCase()
              const filteredData = tableData.filter((item) => JSON.stringify(item).toLowerCase().includes(search))
              const csvData = convertToCSV(filteredData)
              const blob = new Blob([ csvData ], { type: 'text/csv;charset=utf-8;' })
              saveAs(blob, `${artist.name}-releases-filtered-by-${search}.csv`)
            }
          }
        ]}
        hideSearch={isEmpty(tableData)}
      />
      <Card variant="glass" className="flex-shrink-1 flex-grow-0 overflow-hidden">
        <DataTable<BaseRelease | MixRelease, string>
          className={tableClassName}
          columns={tableColumns}
          data={tableData}
          filterValue={searchText}
          emptyMessage={
            <>
              <p>No Releases Available</p>
              <br />
              <Link
                href="/releases/create"
                className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
              >
                Create a New Release
              </Link>
            </>
          }
        />
      </Card>
    </>
  )
}
