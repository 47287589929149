import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  Row,
  useReactTable,
  filterFns,
  getSortedRowModel
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { Fragment, useState } from 'react'
import cn from '@/lib/util/cn'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  emptyMessage?: string | React.ReactElement
  filterValue?: string
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement
  className?: string
  onRowClick?: (row: Row<TData>) => void
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  emptyMessage,
  renderSubComponent,
  filterValue,
  className,
  onRowClick
}: DataTableProps<TData, TValue>) {
  const [ sorting, setSorting ] = useState<SortingState>([])
  const table = useReactTable({
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      globalFilter: filterValue
    },
    data,
    columns,
    manualExpanding: true,
    enableExpanding: true,
    enableGlobalFilter: true,
    enableColumnResizing: true,
    globalFilterFn: filterFns.includesString,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER
    }
  })

  return (
    <Table className={cn('h-full min-h-0 overscroll-none rounded-md border', className)}>
      <TableHeader className="sticky top-0 z-10 bg-black">
        {table.getHeaderGroups().map((headerGroup) =>
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const headerCellWidth = header.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : header.getSize()

              return (
                <TableHead key={header.id} style={{ width: headerCellWidth, minWidth: headerCellWidth }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              )
            })}
          </TableRow>
        )}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ?
          table.getRowModel().rows.map((row) =>
            <Fragment key={row.id}>
              <TableRow
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                className={cn(onRowClick && 'cursor-pointer')}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => {
                  const cellWidth = cell.column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : cell.column.getSize()
                  return (
                    <TableCell
                      style={{
                        width: cellWidth,
                        minWidth: cellWidth
                      }}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  )
                })}
              </TableRow>
              {renderSubComponent && row.getIsExpanded() &&
                <TableRow>
                  <TableCell colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</TableCell>
                </TableRow>
              }
            </Fragment>
          )
         :
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              {emptyMessage || 'No results.'}
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  )
}
