import { nextTrpc } from '@/lib/trpc'
import Loader from '@/components/Loader'
import { withAuth } from '@/lib/hocs/withAuth'
import ReleaseManagementTable from '@/components/tables/ReleaseManagementTable'
import useLocalStorage from '@rehooks/local-storage'
import { useEffect } from 'react'
import { useCreateArtistModal } from '@/lib/useCreateArtistModal'
import { useSession } from 'next-auth/react'

function ReleasesPage() {
  const [ selectedArtistId, setSelectedArtistId ] = useLocalStorage<string>('selectedArtistId')

  const { setIsCreateArtistModalOpen } = useCreateArtistModal()

  const { data: sessionData } = useSession({
    required: false,
    onUnauthenticated: () => {}
  })

  const role = sessionData?.user?.role ?? 'USER'
  const isAdmin = role === 'ADMIN'

  const { data: artistData } = nextTrpc.artist.getAll.useQuery({ limit: 100, page: 1 })
  const { data: artist, isFetched: hasArtistBeenFetched } = nextTrpc.artist.get.useQuery(
    { artistId: selectedArtistId as string },
    { enabled: !!selectedArtistId }
  )
  const { data } = nextTrpc.release.getAll.useQuery({ artistId: artist?.id ?? '' })

  useEffect(() => {
    if (!selectedArtistId && artistData?.length) {
      setSelectedArtistId(artistData[0].id)
    }
  }, [ selectedArtistId, artistData, setSelectedArtistId ])

  const UserMessage = () =>
    <p className="mt-2 min-w-max text-[11px] text-white/60">You will need to contact an administrator to continue.</p>


  const AdminMessage = () =>
    <p className="mt-2 min-w-max text-[11px] text-white/60">
      You will need to{' '}
      <button className="underline" onClick={() => setIsCreateArtistModalOpen(true)}>
        create an artist
      </button>{' '}
      to continue.
    </p>


  const NoArtistsMessage = () =>
    <div className="fixed left-1/2 right-1/2 top-0 flex h-full w-fit -translate-x-1/2 transform items-center lg:h-screen">
      <div className="flex flex-col items-center">
        <span className="mt-[13px] min-w-max text-xs">There are no artists associated with your profile</span>
        {isAdmin ? <AdminMessage /> : <UserMessage />}
      </div>
    </div>


  if (!selectedArtistId) {
    if (!artistData?.length) return <NoArtistsMessage />
    return <Loader />
  }

  if (!artist || !data?.baseReleases || !data?.mixReleases) return <Loader />

  return (
    <div className="flex h-full flex-col gap-8 lg:gap-4">
      <ReleaseManagementTable releases={{ ...data }} artist={artist} />
    </div>
  )
}

export default withAuth('logged-in', ReleasesPage)
