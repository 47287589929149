import React, { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import Loader from '@/components/Loader'
import { useRouter } from 'next/router'
import { Role } from '@/lib/types'

type Roles = 'logged-in' | 'superadmin'

export function withAuth<P>(role: Roles, Component: React.ComponentType<P>): React.ComponentType<P> {
  const displayName = Component.displayName || Component.name || 'withAuth(Component)'

  const HOC = (props: P) => {
    const router = useRouter()
    const { data, status } = useSession({
      required: false,
      onUnauthenticated: () => {}
    })
    const isSuperAdmin = data?.user.role === Role.ADMIN
    useEffect(() => {
      if (status === 'loading') return

      // handle protected routes
      if (role === 'superadmin' && !isSuperAdmin) {
        router.push(`/login?redirectTo=${router.asPath}`)
      }
      if (role === 'logged-in' && status !== 'authenticated') {
        router.push(`/login?redirectTo=${router.asPath}`)
      }
    }, [ router.asPath, router.query, status, isSuperAdmin ])

    if (status === 'loading') return <Loader />

    // props as any because of https://github.com/Microsoft/TypeScript/issues/28938
    return <Component {...(props as any)} />
  }
  HOC.displayName = displayName
  return HOC
}
